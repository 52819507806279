import React from "react";
import { FaCheck } from "react-icons/fa"; // Ícones para melhorar o design

const Planos = () => {
  const whatsappBaseURL = "https://wa.me/551231990322";

  const generateMessage = (planName, price) =>
    encodeURIComponent(
      `Olá, estou interessado no plano "${planName}" com o valor de ${price}. Poderiam me passar mais informações, por favor?`
    );

  return (
    <section id="planos" className="py-5 bg-light text-center">
      <div className="container">
        <h2 className="mb-5 fw-bold">Escolha seu plano</h2>
        <div className="row">
          {/* Plano Mensal */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card shadow border-0 h-100">
              <div className="card-body">
                <h3 className="card-title fw-bold text-secondary">Servidor WPlay</h3>
                <p className="display-4 text-success fw-bold">R$30</p>
                <p className="text-muted">Plano Mensal</p>
                <hr />
                <ul className="list-unstyled text-start">
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    2 Acessos ao Mesmo Tempo
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 3 Mil Canais 4K/FHD/HD/SD
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 500 Canais Internacionais
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 80 Canais 24 Horas
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 100 Canais Adultos +18
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 3 Mil Séries Atualizadas
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 10 Mil Filmes Atualizados
                  </li>
                </ul>
                <a
                  href={`${whatsappBaseURL}?text=${generateMessage(
                    "Plano Mensal",
                    "R$30"
                  )}`}
                  className="btn btn-success btn-lg mt-3 w-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Comprar
                </a>
              </div>
            </div>
          </div>

          {/* Plano Trimestral */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card shadow border-0 h-100">
              <div className="card-body">
                <h3 className="card-title fw-bold text-secondary">Servidor WPlay</h3>
                <p className="display-4 text-success fw-bold">R$75</p>
                <p className="text-muted">Plano Trimestral</p>
                <hr />
                <ul className="list-unstyled text-start">
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    2 Acessos ao Mesmo Tempo
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 3 Mil Canais 4K/FHD/HD/SD
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 500 Canais Internacionais
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 80 Canais 24 Horas
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 100 Canais Adultos +18
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 3 Mil Séries Atualizadas
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 10 Mil Filmes Atualizados
                  </li>
                </ul>
                <a
                  href={`${whatsappBaseURL}?text=${generateMessage(
                    "Plano Trimestral",
                    "R$75"
                  )}`}
                  className="btn btn-success btn-lg mt-3 w-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Comprar
                </a>
              </div>
            </div>
          </div>

          {/* Plano Semestral */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card shadow border-0 h-100">
              <div className="card-body">
                <h3 className="card-title fw-bold text-secondary">Servidor WPlay</h3>
                <p className="display-4 text-success fw-bold">R$140</p>
                <p className="text-muted">Plano Semestral</p>
                <hr />
                <ul className="list-unstyled text-start">
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    2 Acessos ao Mesmo Tempo
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 3 Mil Canais 4K/FHD/HD/SD
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 500 Canais Internacionais
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 80 Canais 24 Horas
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 100 Canais Adultos +18
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 3 Mil Séries Atualizadas
                  </li>
                  <li className="mb-2">
                    <FaCheck className="text-success me-2" />
                    + 10 Mil Filmes Atualizados
                  </li>
                </ul>
                <a
                  href={`${whatsappBaseURL}?text=${generateMessage(
                    "Plano Semestral",
                    "R$140"
                  )}`}
                  className="btn btn-success btn-lg mt-3 w-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Comprar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Planos;
