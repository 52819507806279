import React from "react";

const Header = () => (
  <header
    className="navbar navbar-expand-lg navbar-dark px-5"
    style={{
      backgroundColor: "#000000", // Substitua por a cor exata do fundo da logo
      position: "fixed",
      top: 0,
      width: "100%",
      zIndex: 1030,
    }}
  >
    <div className="container">
      {/* Logotipo centralizado */}
      <a className="navbar-brand mx-auto" href="#hero">
        <img
          src="assets/img/logo.png" // Substitua pelo caminho da sua imagem
          alt="Logo AppTv"
          style={{ height: "40px" }} // Ajuste o tamanho conforme necessário
        />
      </a>
      {/* Botão do menu responsivo */}
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
      </button>
      {/* Links do menu alinhados à direita */}
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <a className="nav-link" href="./">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#features">
              Recursos
            </a>
          </li> 
          <li className="nav-item">
            <a className="nav-link" href="#revenda">
              Revender
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#planos">
              Planos
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contato">
              Contato
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>
);

export default Header;
