import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
// import Creditos from "./components/Creditos";
import Planos from "./components/Planos";
import PlanosRevemda from './components/PlanosRevemda';
import Revenda from "./components/Revenda";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Header />
      <Hero />
      <Features />
      {/* <Creditos /> */}
      <Planos />
      <Revenda />
      <PlanosRevemda />
      <FAQ />
      <Footer />
    </>
  );
}

export default App;